export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/admin/kiosks',
    name: 'admin-kiosks',
    component: () => import('@/views/admin/kiosks/kiosks-list/KiosksList.vue'),
    meta: {
      pageTitle: 'Kiosks',
    },
  },
  {
    path: '/admin/kiosk-add',
    name: 'admin-kiosk-add',
    component: () => import('@/views/admin/kiosks/kiosk-add/KioskAdd.vue'),
    meta: {
      pageTitle: 'Kiosks',
    },
  },
  {
    path: '/admin/kiosk-edit/:id',
    name: 'admin-kiosk-edit',
    component: () => import('@/views/admin/kiosks/kiosk-edit/KioskEdit.vue'),
    meta: {
      pageTitle: 'Kiosks',
    },
  },
  {
    path: '/admin/kiosk-duplicate/:id',
    name: 'admin-kiosk-duplicate',
    component: () => import('@/views/admin/kiosks/kiosk-duplicate/KioskDuplicate.vue'),
    meta: {
      pageTitle: 'Kiosks',
    },
  },
  {
    path: '/admin/settings',
    name: 'admin-settings',
    component: () => import('@/views/admin/settings/Settings.vue'),
    meta: {
      pageTitle: 'Settings',
    },
  },
  {
    path: '/admin/change-password',
    name: 'change-password',
    component: () => import('@/views/admin/change-password/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin/users/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
    },
  },
  {
    path: '/admin/user-add',
    name: 'admin-user-add',
    component: () => import('@/views/admin/users/user-add/UserAdd.vue'),
    meta: {
      pageTitle: 'User',
    },
  },
  {
    path: '/admin/user-edit/:id',
    name: 'admin-user-edit',
    component: () => import('@/views/admin/users/user-edit/UserEdit.vue'),
    meta: {
      pageTitle: 'Users',
    },
  },
]
