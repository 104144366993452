import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    isUserRole: JSON.parse(localStorage.getItem('userData'))?.role || null,
  },
  getters: {
    getUserRole: state => state.isUserRole,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_USER_ROLE(state, val) {
      state.isUserRole = val
    },
  },
  actions: {},
}
